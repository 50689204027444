@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,600;1,700;1,800;1,900&display=swap');

@font-face {
	font-family: 'NanumSquare';
	src: url(../../src/assets/fonts/NanumSquare.ttf);
}
@font-face {
	font-family: 'NanumSquareB';
	src: url(../../src/assets/fonts/NanumSquareB.ttf);
}
@font-face {
	font-family: 'NanumSquareEB';
	src: url(../../src/assets/fonts/NanumSquareEB.ttf);
}
@font-face {
	font-family: 'NanumSquareL';
	src: url(../../src/assets/fonts/NanumSquareL.ttf);
}
@font-face {
	font-family: 'NanumSquareR';
	src: url(../../src/assets/fonts/NanumSquareR.ttf);
}

* {
  font-family:
    'Work Sans',
    'Oswald', 
    'Nanum Gothic', 
    'Do Hyeon', 
    'NanumSquare', 
    'NanumSquareB', 
    'NanumSquareEB', 
    'NanumSquareL', 
    'NanumSquareR',
    'Monoton',
    'Bebas Neue',
    sans-serif, 
    cursive;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1rem;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
html {
  font-size: 16px;
}
div{ 
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
menu,
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0 auto;
  font-family: 'Oswald', 'Nanum Gothic', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  line-height: 1.2;
  background-color: #fff;
  transform: skew(-0.05deg);
}

a {
  color: inherit;
  text-decoration: none;
}
input {
  -webkit-tap-highlight-color: transparent;
}
button {
  padding: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}